// TODO: Use this for all action bar filters?
import * as lodash from "lodash-es";
import { DateTime } from "luxon";
import { AdNetwork } from "@/components/Core/ActionBar/formats";
import { FeedItemType } from "./feed";
import { ResourceChannel } from "./resources";
import { Conversion, ConversionGroup } from "./conversions";
import { ALLOWED_COUNTRIES } from "@/utils/countries";

// Corresponds to options in backend endpoints
export const SEARCH_TERM_TYPES = ["total", "paid", "organic"] as const;
// Uses "no" (norway) locale for sorting, so ex. "Åland" is not sorted as "A"
export const COUNTRIES = [
  "total",
  ...ALLOWED_COUNTRIES.sort((a, b) =>
    a.name.localeCompare(b.name, "no"),
  ).flatMap((c) => c.alpha3),
] as const;
export type SearchTermDataFilter = (typeof SEARCH_TERM_TYPES)[number];
export type CountryDataFilter = (typeof COUNTRIES)[number];

// In short, the above converts DataFilters' keys from camelCase to django__case, while also allowing the "not__" prefix and "__in" suffix

export function payloadToQueryParams(
  value: string | string[] | number[] | undefined,
): string | string[] | number[] | undefined | null {
  if (lodash.isArray(value)) {
    if (value.length === 0) {
      return null;
    } else {
      return value;
    }
  } else {
    return value;
  }
}

export interface ChannelDataFilter {
  channel__in: ResourceChannel[] | null;
}

export interface AdNetworkFilter {
  subcategory__in: AdNetwork[] | null;
}

export interface ConversionFilter {
  // Note: These filters are combined with AND, not OR.
  // That means if you select a conversion group and a
  // conversion not in that conversion group at the same time,
  // you will get no data.
  conversion_group__in: ConversionGroup["pk"][] | null;
  conversion__in: Conversion["pk"][] | null;
}

export interface FeedItemFilter {
  created_by__in: number[] | null;
  item_type__in: FeedItemType[] | null;
}

export type TemporalPeriodsType = "selection" | "comparison";
export const TemporalPeriods: TemporalPeriodsType[] = [
  "selection",
  "comparison",
] as const;

export interface SelectionTemporalDataFilter {
  selection_from: string | null;
  selection_to: string | null;
}

export interface ComparisonTemporalDataFilter {
  comparison_from: string | null;
  comparison_to: string | null;
}

export type TemporalDataFilter = SelectionTemporalDataFilter &
  ComparisonTemporalDataFilter;

export interface TagsDataFilter {
  tags__in: number[] | null;
  not__tags__in: number[] | null;
}

export interface ResourcesDatafilter {
  resource__in: number[] | null;
}

export interface BrandAndCategoryFilter {
  brand: string[] | null;
  category: string[] | null;
}

export const marketingHierarchyTagFilterKeys = [
  "account_tags__in",
  "not__account_tags__in",
  "campaign_tags__in",
  "not__campaign_tags__in",
  "ad_group_tags__in",
  "not__ad_group_tags__in",
  "ad_tags__in",
  "not__ad_tags__in",
  "keyword_tags__in",
  "not__keyword_tags__in",
  "query_tags__in",
  "not__query_tags__in",
] as const;

type MarketingHierarchyTagFilterKey =
  (typeof marketingHierarchyTagFilterKeys)[number];

export type MarketingHierarchyTagFilter = Record<
  MarketingHierarchyTagFilterKey,
  number[] | null
>;

export interface MarketingHierarchySelectedEntityFilter {
  account_pk__in: number[] | null;
  campaign_pk__in: number[] | null;
  ad_group_pk__in: number[] | null;
  ad_pk__in: number[] | null;
  ad_group_keyword_pk__in: number[] | null;
  ad_group_query_pk__in: number[] | null;
}

export interface DomainFilter {
  domain__in: string[] | null;
}

export interface SeparateResourcesFilter {
  separateResources: boolean | null;
}

export interface IsConversionEventFilter {
  is_conversion_event: boolean | null;
}

export interface PKFilter {
  pks?: number[];
}

export interface YearFilter {
  year: string;
}

// Expand this with more filters as we go on
export type DataFilters = ChannelDataFilter &
  TemporalDataFilter &
  TagsDataFilter &
  ResourcesDatafilter &
  BrandAndCategoryFilter &
  AdNetworkFilter &
  MarketingHierarchyTagFilter &
  DomainFilter &
  SeparateResourcesFilter &
  IsConversionEventFilter &
  PKFilter &
  YearFilter;

/* --- DATEPICKER STUFF --- */
export interface PresetRange {
  start: DateTime;
  end: DateTime;
}
export interface Preset {
  label(): string;
  disabled?(): boolean;
  range(): PresetRange;
  comparisonPresets: Record<string, ComparisonPreset>;
}

export interface ComparisonPreset {
  label: string;
  disabled?: boolean;
  range(): PresetRange;
}

export interface AvailablePresets {
  // custom: Preset,
  today: Preset;
  yesterday: Preset;
  last7days: Preset;
  last30days: Preset;
  last60days: Preset;
  last90days: Preset;
  thisMonth: Preset;
  lastMonth: Preset;
  currentQuarter: Preset;
  qMinus1: Preset;
  qMinus2: Preset;
  qMinus3: Preset;
  thisYear: Preset;
  lastYear: Preset;
}
export interface DateTimeISODateRange {
  start: string;
  end: string;
}
export interface DateTimeISODateRangeWithPresetLabel
  extends DateTimeISODateRange {
  preset: string;
}
export interface DateRangeSelection {
  selection: DateTimeISODateRangeWithPresetLabel;
  comparison?: DateTimeISODateRangeWithPresetLabel;
}

export enum TimeUnit {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  WEEKDAY = "weekday",
}
