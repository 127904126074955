import { createApp } from "vue";
import App from "@/App.vue";
import "@/assets/styles/index.css";
import router from "@/router";
import { createGtm } from "@gtm-support/vue-gtm";
import { createHead } from "@unhead/vue";
import { VueQueryPlugin, VueQueryPluginOptions } from "@tanstack/vue-query";
import axios from "axios";
import { createPinia } from "pinia";
import FloatingVue from "floating-vue";

// Below is anything that should happen on app "boot/startup"

const app = createApp(App);

// Production environment
import {
  init,
  browserTracingIntegration,
  extraErrorDataIntegration,
  setTag,
} from "@sentry/vue";

if (import.meta.env.VITE_APP_SENTRY_DSN) {
  init({
    app,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN as string,
    release: `fyr@${import.meta.env.VITE_APP_VERSION}`,
    environment: import.meta.env.MODE,
    integrations: [
      extraErrorDataIntegration({ depth: 7 }),
      browserTracingIntegration({
        router,
      }),
    ],
    normalizeDepth: 8,
    logErrors: true,
    tracesSampleRate: 0.1,
  });
  setTag("layer", "frontend");
}

// All environments
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import "@egjs/vue3-flicking/dist/flicking.css";

const head = createHead();

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        retry: (failureCount, error) => {
          if (
            axios.isAxiosError(error) &&
            (error.response?.status === 404 || error.response?.status === 429)
          ) {
            return false;
          }
          return failureCount < 3;
        },
        staleTime: 10 * 1000,
      },
    },
  },
};

app
  .use(router)
  .use(FloatingVue, {
    // https://floating-vue.starpad.dev/guide/config.html#default-values
  })
  .use(
    createGtm({
      id: "GTM-NJ6Q4TZ",
      defer: false,
      compatibility: false,
      enabled: import.meta.env.MODE === "production",
      debug: import.meta.env.MODE !== "production",
      vueRouter: router,
    }),
  )
  .use(head)
  .use(VueQueryPlugin, vueQueryPluginOptions)
  .use(createPinia())
  .mount("#app");
